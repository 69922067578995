import type { SelectListItem } from '../lib/types/popoverAndDrawerTypes'
import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import MuiPopover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ImageWithFallback from './ImageWithFallback'
import Link from './Link'

const Popover = ({
    trigger,
    listItems,
    iconList = true,
}: {
    trigger: React.ReactElement<{ onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void }, string>
    listItems: SelectListItem[]
    iconList?: boolean
}): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const Trigger = React.cloneElement(trigger, {
        onClick: handleClick,
    })

    const open = Boolean(anchorEl)

    return (
        <Box>
            {Trigger}
            <MuiPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{ maxHeight: '440px' }}
            >
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'common.white' }}>
                    <List>
                        {listItems.map(({ key, text, icon, url, onClick, imgAspectRatio = 1 }) => (
                            <React.Fragment key={key}>
                                {url !== '#' && (
                                    <Link href={url}>
                                        <ListItem sx={{ gap: 2 }} dense button onClick={handleClose}>
                                            {iconList && (
                                                <ListItemIcon
                                                    sx={{
                                                        position: 'relative',
                                                        height: 35,
                                                        minWidth: 35 * imgAspectRatio,
                                                    }}
                                                >
                                                    {icon && (
                                                        <ImageWithFallback
                                                            alt="list item image"
                                                            src={icon}
                                                            fallbackSize={{ sm: 35 }}
                                                            priority
                                                        />
                                                    )}
                                                </ListItemIcon>
                                            )}
                                            <Typography
                                                sx={{ color: 'text.primary', fontWeight: 'medium' }}
                                                variant="subtitle1"
                                            >
                                                {text}
                                            </Typography>
                                        </ListItem>
                                    </Link>
                                )}

                                {onClick && (
                                    <ListItem
                                        onClick={() => {
                                            onClick()
                                            handleClose()
                                        }}
                                        sx={{
                                            cursor: 'pointer',
                                            ':hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{ color: 'text.primary', fontWeight: 'medium' }}
                                            variant="subtitle1"
                                        >
                                            {text}
                                        </Typography>
                                    </ListItem>
                                )}

                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            </MuiPopover>
        </Box>
    )
}

export default Popover
