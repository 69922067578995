import type { SelectListItem } from '../lib/types/popoverAndDrawerTypes'
import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Typography from '@mui/material/Typography'
import Link from './Link'
import ImageWithFallback from './ImageWithFallback'

type Anchor = 'top' | 'left' | 'bottom' | 'right'

type TemporaryDrawerProps = {
    listItems: SelectListItem[]
    anchor: Anchor
    trigger: React.ReactElement<{ onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void }, string>
    iconList?: boolean
}

export default function TemporaryDrawer({
    listItems,
    anchor,
    trigger,
    iconList = true,
}: TemporaryDrawerProps): JSX.Element {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })

    const toggleDrawer = (anchorDirection: Anchor, open: boolean) => {
        setState({ ...state, [anchorDirection]: open })
    }

    const Trigger = React.cloneElement(trigger, {
        onClick: () => toggleDrawer(anchor, true),
    })

    const list = (anchorDirection: Anchor) => (
        <Box
            sx={{ width: anchorDirection === 'top' || anchorDirection === 'bottom' ? 'auto' : 250 }}
            onClick={() => toggleDrawer(anchorDirection, false)}
        >
            <List>
                {listItems.map(({ key, text, icon, url, imgAspectRatio = 1 }) => (
                    <React.Fragment key={key}>
                        <Link href={url}>
                            <ListItem sx={{ gap: 2 }} button>
                                {iconList && (
                                    <ListItemIcon
                                        sx={{
                                            position: 'relative',
                                            minWidth: 40 * imgAspectRatio,
                                            height: 40,
                                        }}
                                    >
                                        {icon && (
                                            <ImageWithFallback
                                                src={icon}
                                                fallbackSize={{ sm: 40 }}
                                                priority
                                                alt="list item image"
                                            />
                                        )}
                                    </ListItemIcon>
                                )}
                                <Typography sx={{ color: 'text.primary', fontWeight: 'medium' }} variant="subtitle1">
                                    {text}
                                </Typography>
                            </ListItem>
                        </Link>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
        </Box>
    )

    return (
        <>
            {Trigger}
            <Drawer
                variant="temporary"
                anchor={anchor}
                open={state[anchor]}
                onClose={() => toggleDrawer(anchor, false)}
            >
                <ListItem
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'sticky',
                        top: 0,
                        minHeight: '50px',
                        backgroundColor: 'common.white',
                        zIndex: 1,
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                    onClick={() => toggleDrawer(anchor, false)}
                    button
                >
                    <ExpandLessIcon />
                </ListItem>
                {list(anchor)}
            </Drawer>
        </>
    )
}
